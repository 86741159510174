
:root{
  --font-family: poppins, sans-serif;
  --bg-primary:#0D3885;
  --bg-btn-b:#1976d2;
  --color-btn:#fff;
  --color-text:#fff;
  --tag-green:#389e0d;
  --tag-red:#cf1322;
  --tag-blue:#096dd9;
  --box-shadow-card:1px 2px 6px rgb(0 0 0 / 10%);
  --color-title:#000;
}

body {
  margin: 0;
  font-family:var(--font-family)!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:linear-gradient(368deg,#4346e2,#1818a3,#141579,#06063f);
 
}
.ant-layout{
  
  background:linear-gradient(308deg,#000123,#00000073,#18186ee6,#000000)!important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}
h6 ,.h6{
  color:var(--color-text)!important;
  font-weight: 600!important;
}
h1,.h1{
  color:var(--color-text)!important;
  font-size: 2rem!important;
}
h4,.h4{
  color:var(--color-text)!important;
  font-size: 2rem!important;
}
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}


.logo{
  padding-top:60px;
}
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  color:var(--color-text)!important;
  background: transparent!important;
}
.ant-menu-dark .ant-menu-item > span > a ,.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a{
  text-decoration: none;
  color:var(--color-text)!important;
}
.ant-layout-footer {
  padding: 14px 50px!important;
  color: var(--color-btn)!important;
  font-size: 14px!important;
  background: var(--bg-primary)!important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ant-layout-footer img{
  height: 24px;
  padding: 0 5px;
}
.ant-btn-primary {
  color: var(--color-btn)!important;
  border-color: var(--bg-primary)!important;
  background: var(--bg-primary)!important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%)!important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  font-weight: 500!important;
  padding: 15px 10px!important;
  border-radius: 10px!important;
}

.cardInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: hsl(247.47deg 55.39% 29.59% / 30%);
  border: 0.0625rem solid hsl(0deg 0% 97.01% / 13%);
  color: var(--color-text) ;
  padding: 30px 20px;
  border-radius: 10px;
  margin: 15px 0;
  box-shadow: var(--box-shadow-card);
}
.InfoToken {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: var(--color-text);
  box-shadow: var(--box-shadow-card);
  padding: 20px;
  border-radius: 10px;
  margin: 15px 0;
  gap: 10px;
  background-color: hsl(247.47deg 55.39% 29.59% / 30%);
  border: 0.0625rem solid hsl(0deg 0% 97.01% / 13%);
}
.InfoToken2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: var(--color-text);
  box-shadow: var(--box-shadow-card);
  padding: 30px 20px;
  border-radius: 10px;
  margin: 15px 0;
  gap: 10px;
  background-color: hsl(247.47deg 55.39% 29.59% / 30%);
  border: 0.0625rem solid hsl(0deg 0% 97.01% / 13%);
}
.footerMo{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    gap: 10px;
}
.footerMo button{
  min-width: 150px;
}
.tokenInfoD{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.MuiIconButton-root{
  color:var(--color-text)!important;
}

.addToken{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.infoCardD{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.d-none{
  display:none;
}
.alignChoix{
  display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.alignChoix button{
  line-height: .5;
  background-color: transparent;
}
.alignChoix .dropdown-item{
  color:var(--color-text);
}
.alignChoix .dropdown-menu.show{
  background: var(--color-btn);
}
.pr5{
  padding-right: 5px;
}

@media (max-width: 767.98px) {
  .statust {
    display: flex;
    flex-direction: column!important;
    margin-bottom: 15px;
  }
  .AlignProgress {
   
    grid-template-columns: 100%!important;
  }
  .footerMo {
    display: flex;
    flex-direction: column!important;
  }

  .proposalT,.proposalCardL2,.proposalCard{
    grid-template-columns: 100%!important;
    gap:5px!important;
  }
  .ant-layout-footer {
    flex-direction: column!important;
    align-items: center!important;
    padding: 5px!important;
  }
  .ant-tooltip-inner a{
    color:var(--color-text)!important;
  }

  
  .addToken {
    flex-direction: column!important;
    align-items: flex-start!important;
  }
  .tabTo .MuiToolbar-regular{
    zoom:.8;
  }
  .alignChoix {
    flex-direction: column!important;
  }
.cardInfo{
  flex-direction: column!important;
  gap:15px;
}
.ant-layout-sider-trigger{
  display:none;
}
.walletm span{
  white-space: nowrap;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block!important;
}
}
.ant-menu-inline-collapsed-tooltip a, .ant-menu-inline-collapsed-tooltip a:hover {
  color: var(--color-text);
  text-decoration: none;
}
.ant-tooltip-inner {
  background-color:  var(--bg-btn-b)!important;
}
.ant-tooltip-inner a {
 
  color:var(--bg-primary);
  font-weight: 600;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color:  var(--bg-btn-b)!important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector , .ant-picker{

  background-color: transparent!important;
  border-color: var(--bg-btn-b)!important;
}
.ant-select-arrow ,.ant-picker-separator ,.ant-picker-suffix{
  color:  var(--bg-btn-b)!important;

}
.ant-select {
  color: var(--color-text)!important;
}
.cardInfoform {
  justify-content: normal!important;
  gap:10px;
}
.ant-picker-input > input{
  color:var(--color-text)!important;
}
.ant-progress-text{
  color:var(--color-text)!important;
}
.walletm button{
  color:var(--color-text)!important;
}
.walletm button:hover, .walletm button:focus, .walletm button:active {
  text-decoration: none;
  background: #000;
}
.MuiPaper-rounded {
  border-radius: 10px!important;
 
}
.MuiPaper-root{
  background-color: hsl(247.47deg 55.39% 29.59% / 30%)!important;
  border: 0.0625rem solid hsl(0deg 0% 97.01% / 13%)!important;
  color:var(--color-text)!important;
  box-shadow: var(--box-shadow-card)!important;

}
table th{
  background: transparent!important;
  color:#2196F3!important;
  font-size: 1rem!important;
  text-transform: uppercase!important;
}
.MuiTableCell-root{
  border-bottom: 1px solid transparent!important;
}
.tabTo .MuiToolbar-regular {
  min-height: 15px!important;
}
.tabTo table tr td:nth-child(2),.tabTo table tr td:nth-child(3){
  text-align: right!important;
}
.tabTo table tr th:nth-child(2),.tabTo table tr th:nth-child(3){
  text-align: right!important;
}
.MuiTablePagination-root {
  color: var(--color-text)!important;
}
.MuiSelect-icon {
  color: var(--color-text)!important;
}
.MTablePaginationInner-root-14 {
  color:var(--color-text)!important;
}
.MuiIconButton-root.Mui-disabled,.MuiTypography-root{
  color:var(--color-text)!important;
}
.stitle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

/*menu*/
.ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > span > a, .ant-menu-dark .ant-menu-item-selected > a:hover, .ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: var(--bg-btn-b)!important;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon, .ant-menu-dark .ant-menu-item-selected .anticon {
  color: var(--bg-btn-b)!important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff!important;
  border: 1px solid #fff!important;
}

.ant-pagination-item {
  background-color: var(--bg-primary)!important;
  border: 1px solid var(--bg-primary)!important;
  color: var(--color-text)!important;
}


.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link ,.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  color: var(--bg-primary)!important;
  border-color: transparent!important;
  background: transparent!important;
}
.homeB{
  display: flex;
    flex-direction: row;
    justify-content: center;
}
.homeB .cardInfo{
  width: 900px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.cardc{
  min-height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.cardc button{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
}
.AlignProgress{
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: space-around;
}
.MuiTableSortLabel-icon{
  display: none!important;
}
.MuiTableSortLabel-root:hover {
  color: var(--color-text)!important;
}
.anticon-eye{
  vertical-align: text-top!important;
}
.ant-tag-green {
  color: var(--color-text)!important;
  background: var(--tag-green)!important;
  border-color: var(--tag-green)!important;
  border-radius: 12px!important;
  
}
.ant-tag-red {
  color: var(--color-text)!important;
  background: var(--tag-red)!important;
  border-color: var(--tag-red)!important;
  border-radius: 12px!important;
}
.ant-tag-blue {
  color: var(--color-text)!important;
  background: var(--tag-blue)!important;
  border-color: var(--tag-blue)!important;
  border-radius: 12px!important;
}
.ant-progress-text {
  font-size: .9em!important;
}

.ant-progress-inner {
  background-color: #dddddd!important;
}
.brR{
  border-right: 5px solid var(--color-btn)!important;
}
.alignprop{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.alignprop button {
  font-size: 12px!important;
  border-radius: 21px!important;
  padding: 0 7px!important;
  min-width: 55px;
  height: auto!important;
  font-weight: 500!important;
}
.alignprop button:hover{
  background-color: transparent!important;
  color: var(--bg-primary)!important;
  border: 1px solid var(--bg-primary);
}

/****modal***/
.ant-form-horizontal .ant-form-item-label{
  width:30%;
}
.ant-form-item-label{
  text-align: left!important;
}
.ant-modal-header ,.ant-modal-content {
  background: var(--color-btn)!important;
}
.ant-modal-content  .ant-form-item-label > label ,.ant-modal-content .ant-form-item ,.ant-modal-close  ,.ant-modal-title{
  color:var(--bg-primary)!important;
}
.ant-modal-header {
  border-bottom: 1px solid transparent!important;
}
.ant-modal-confirm-body .ant-modal-confirm-title ,.ant-modal-confirm-body .ant-modal-confirm-content {
  
    color: var(--bg-primary)!important;
}
.ant-modal-mask {
  background-color: rgb(0 0 0 / 80%)!important;
}
.ant-modal-content {
  border: 3px solid var(--bg-btn-b)!important;
  border-radius: 25px!important;
}
.ant-modal-header {
  border-radius: 25px!important;
}
.ant-layout-header {
    background: var(--bg-primary)!important;
    box-shadow: var(--box-shadow-card);
}
.ant-layout-sider-trigger {
  background: var(--bg-btn-b)!important;
}
.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: var(--bg-btn-b)!important;
}

button{
   display: flex!important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.alignV{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.alignV button{
  font-size: 14px;
  min-width:185px;
}
.ant-btn-round.ant-btn-sm {
  height: 16px!important;
  padding: 0px 5px!important;
  font-size: 14px;
  border-radius: 24px;
}
table tr{
  height: auto!important;
}
.alignView{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
}
.ant-progress-small.ant-progress-line .ant-progress-text .anticon{
  color: red!important;
}
.proposalT{
  display: grid;
  grid-template-columns: 75% 25%;
  align-items: center;
}
.proposalText{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}
.proposalCard{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
}
.proposalCardL2{
  display: grid;
  grid-template-columns: 2.05fr 1fr;
  gap: 15px;
  align-items: start;
}
.proposalCardG{
  display: flex;
  flex-direction: column;
  color: var(--bg-primary);
  box-shadow: var(--box-shadow-card);
  padding: 30px 20px;
  border-radius: 10px;
  margin: 15px 0;
  gap: 10px;
}
.createBy{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid #fff;
  color: #fff;
  padding: 5px 15px;
 
}
.createBy div:first-child {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.createBy small{
  overflow: hidden;
  width: 25%;
  text-overflow: ellipsis;
}
.statusH{
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
}
.statusH small{
  color:#AAB8C1;
  font-size: 12px; 
}
.voteAdress{
  width: 40%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.voteBg{
  background-color: var(--color-text)!important;
  color:var(--bg-primary)!important;
  box-shadow: var(--box-shadow-card);
}
.viewS{
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  text-align: center;
  padding-top:10px ;
  padding-bottom: 10px;
}
.viewS:hover{
  border:1px solid #fff!important;
  color:#fff!important;
}
.adV{
  color: #53b4d6;
  font-weight: 600;
}
.small{
    width: 30%;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--color-text)!important;
    font-weight: 600!important;
    
}
.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next{
  min-width:10px!important;
}
.centerBH{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.navbar-nav .nav-link{
  color:var(--color-text);
}
.nav-link:hover, .nav-link:focus {
  color: #2196F3!important;
}
.navbar-toggler-icon{
  background-color: var(--bg-primary)!important;
  height: 1.8em!important;
  border-radius: 5px;
}

@media (min-width: 992px){
  
  .navbar-expand-lg .navbar-toggler {
  display: none!important;
}}


.ant-btn > .anticon + span, .ant-btn > span + .anticon{
  margin-left: 2px!important;
}

.alignSC{
  display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.alignSC small{
  font-weight: 600;
  
}

.ant-tag-purpel {
  color: var(--color-text)!important;
  background: purple!important;
  border-color: purple!important;
  border-radius: 12px!important;
}
.ant-tag-magenta {
  color: var(--color-text)!important;
  background: magenta!important;
  border-color: magenta!important;
  border-radius: 12px!important;
}
.AlignBP{
  display: flex;
    justify-content: center;
    flex-direction: row;
}
.ant-spin{
  color:#fff!important;
}
.alignBou{
  display: flex;
    flex-direction: column;
    gap: 10px;
}
.alignBou button{
  background-color: transparent;
  border-color:var(--bg-primary);
  border-radius: 14px;
  color: var(--bg-primary);
  font-size: 14px;
  border-style: solid;
  font-weight: 600;
}
h5 ,.h5{
  margin-bottom: 0!important;
}
.hB:hover{
  border-color:var(--bg-primary);
  background-color: var(--bg-primary);
  color: #fff;
}
.hB2:hover{
  border-color: var(--tag-blue);
  background-color: var(--tag-blue);
  color: #fff;
}
.hv:hover{
  border-color: var(--tag-green);
  background-color: var(--tag-green);
  color: #fff;
}
.hR:hover{
  border-color: var(--tag-red);
  background-color: var(--tag-red);
  color: #fff;
}
.moreI .ant-tag{
  border-radius: 14px;
    border-color: var(--bg-primary);
    color: var(--bg-primary);
}
.statust{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.deuP{
  display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
}
.vote__modal__body__delegate-option {
  display: flex;
  padding: 2.5625rem;
  width: 100%;
  cursor: pointer;
  position: relative;
  border-left: 3px solid transparent;
  transition: border-color 0.3 ease-in-out;
}
.vote__modal__body__delegate-option__icon {
  flex-shrink: 0;
  height: 2.75rem;
  width: 2.75rem;
  border-radius: 50%;
}
.vote__modal__body__delegate-option__text-view {
  display: flex;
  flex-direction: column;
  margin-left: 1.1rem;
  max-width: 15.6875rem;
}
.vote__modal__body__delegate-option__text-view__title {
  line-height: 1;

  
}

.delegS{
  background: #2196F3!important;
  border: 1px solid #2196F3!important;
  color: #fff!important;
  border-radius: 10px;
}
.desChat{
  position:absolute;
  bottom: 0;
  right:10px;
  z-index: 9999;
}
.cs-chat-container {
  
  width: 500px!important;
  height: 600px!important;
}